<template>
  <TunnelLayout>
    <template #form>
      <div class="flex flex-col">
        <h2 class="font-bold text-3xl text-promy-blue-300 sm:text-base">
          Il s’agit d’une résidence...
        </h2>
        <div class="flex mt-8 space-x-4 items-baseline">
          <div>
            <validation-provider
              class="relative flex flex-col"
              rules="required"
              v-slot="{ errors }"
            >
              <div class="flex gap-2">
                <selectable-button
                  v-for="(residence, index) in residences"
                  :key="residence + index"
                  v-model="form.informations.residence"
                  :option-name="residence"
                />
              </div>
              <span
                v-show="errors[0]"
                class="mt-1 text-xs text-promy-red-400"
                >{{ errors[0] }}</span
              >
            </validation-provider>
          </div>
        </div>
      </div>
    </template>
    <template #buttons>
      <Buttons :form="form" :goNext="is_invalide" />
    </template>

    <template slot="right-side">
      <div class="flex flex-col relative">
        <div
          v-if="!parcelles_selected.length && map_is_loaded"
          class="
            font-bold
            text-white
            bg-promy-orange-100
            p-6
            rounded-full
            w-fit
            sm:w-full
            absolute
            bottom-11
            left-1/2
            transform
            -translate-x-1/2
            text-center
            sm:py-4
            z-50
          "
        >
          Merci de sélectionner une parcelle
        </div>

        <Map
          :showBtnParcelle="true"
          :form="form"
          :cadastre="cadastre"
          ref="mapTunnelRevelationPotentiel"
          @mapLoaded="map_is_loaded = true"
        />
      </div>
    </template>
  </TunnelLayout>
</template>
<script>
import mapbox from '@/mixins/map/mapbox'
import Buttons from './components/Buttons.vue'
import Map from './components/Map.vue'

export default {
  components: {
    Buttons,
    Map,
  },
  mixins: [mapbox],
  props: {
    form: {
      type: Object,
      required: true,
    },
    cadastre: {
      type: Object,
      require: true,
    },
    invalid: Boolean,
  },
  data() {
    return {
      residences: ['Principale', 'Secondaire'],
      map_is_loaded: false,
    }
  },
  computed: {
    parcelles_selected() {
      return [
        this.form.informations.current_parcelle,
        ...this.form.informations.parcelles_voisines,
      ].filter((parcelle) => parcelle)
    },
    is_invalide() {
      return this.invalid || this.parcelles_selected.length === 0
    },
  },
  mounted() {},
}
</script>
